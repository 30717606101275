// NOTE: remember to update the UI-texts file on the drive, if you update this file */
const loginUiTexts = {
	start: {
		se: 'Starta',
		en: 'Start',
	},
	login: {
		se: 'Logga in',
		en: 'Log in',
	},
	logout: {
		se: 'Logga ut',
		en: 'Log out',
	},
	player: {
		se: 'Spelare',
		en: 'Player',
	},
	facilitator: {
		se: 'Kontaktperson',
		en: 'Facilitator',
	},
	admin: {
		se: 'Administratör',
		en: 'Administrator',
	},
	email: {
		se: 'e-post',
		en: 'e-mail',
	},
	password: {
		se: 'lösenord',
		en: 'password',
	},
	passwordRepeat: {
		se: 'upprepa lösenord',
		en: 'repeat password',
	},
	userId: {
		se: 'anställningsnr',
		en: 'employee no.',
	},
	name: {
		se: 'namn',
		en: 'name',
	},
	loginWithUserId: {
		se: 'Logga in med anställningsnr',
		en: 'Log in with employee no.',
	},
	createNewUser: {
		se: 'Skapa ny användare',
		en: 'Create new user',
	},
	forgotPassword: {
		se: 'Har du glömt lösenordet?',
		en: 'Forgot your password?',
	},
	resetPassword: {
		se: 'Återställ lösenord',
		en: 'Reset tpassword',
	},
	goToLoginBtn: {
		se: 'Tillbaka till inloggning',
		en: 'Back to login',
	},
	createUserBtn: {
		se: 'Skapa',
		en: 'Create',
	},
	reset: {
		se: 'Återställ',
		en: 'Reset',
	},
	cancel: {
		se: 'Avbryt',
		en: 'Cancel',
	},
	cancel2: {
		se: 'Avbryt',
		en: 'Cancel',
	},
	createUserFeedback: {
		se: 'Din nya användare har skapats. Gå tillbaka till inloggningen för att spela.',
		en: 'Your new user has been created. Go back to log in to play.',
	},
	passwordResetFeedback: {
		se: 'Du har fått en länk för att återställa lösenordet i din inkorg.',
		en: 'You have received a link to reset your password in your inbox.',
	}
};




module.exports = {
	loginUiTexts
};