// NOTE: remember to update the UI-texts file on the drive, if you update this file */

const cookiesUiTexts = {
	title: {
		se: 'Vi använder kakor',
		en: 'We use cookies',
	},
	text: {
		se: `Vi använder kakor för att förbättra spelupplevelsen. De hanterar din åtkomst till spelet, och spelet fungerar inte utan dem.
			<br /><br />Tryck på ""OK"" för att samtycka till att vi använder kakor.
			<br /><br />Ditt samtycke är giltigt i ett år. Du kan när som helst dra tillbaka ditt samtycke genom att klicka på ikonen nere till vänster.`,
		en: `We use cookies to improve your game experience. They handle your access to the game, and the game will not work without them. 
			<br /><br />Press "OK" to give us your consent to using cookies. 
			<br /><br />Your consent is valid for a year. You can at any time withdraw your consent by clicking on the icon in the bottom left corner. `,
	},
	okBtn: {
		se: 'OK',
		en: 'OK',
	},
	rejectBtn: {
		se: 'Avvisa',
		en: 'Reject',
	}
};

module.exports = {
	cookiesUiTexts
};