import appConfig from 'config/app.config';
import {languagesData} from 'data/languages-data';

/**
 * Get browser language
 * @returns {string}
 */
function getBrowserLanguage() {
	let browserLanguage = window.navigator.userLanguage || window.navigator.language;
	if (browserLanguage) browserLanguage = browserLanguage.split('-')[0];

	if (!browserLanguage || !languagesData.some((lng) => {return lng.id === browserLanguage.toString();})) {
		browserLanguage = appConfig.defaultLanguage;
	}

	return browserLanguage;
}

/**
 * Translate text
 * @param {object} textObj 
 * @param {string} languageId 
 * @returns {string}
 */
function getText(textObj, languageId) {
	if (textObj && textObj.hasOwnProperty(languageId)) {
		return textObj[languageId];
	}

	if (typeof textObj === 'string') {
		console.error('Translation error: ', textObj, ' ', languageId);
		return textObj;
	}
	
	console.error('Translation error: ', textObj, ' ', languageId);
	// Return danish default if translation does not exist
	if (textObj && textObj.hasOwnProperty('se')) {
		return textObj['se'];
	}
	return '';
}

export {getBrowserLanguage, getText};