// NOTE: remember to update the UI-texts file on the drive, if you update this file */

const playerUiTexts = {
	/* Login */
	workerId: {
		se: 'Är det här ditt anställningsnummer?',
		en: 'Is this your employee number?',
	},
	yes: {
		se: 'Ja',
		en: 'Yes',
	},
	no: {
		se: 'Nej',
		en: 'No',
	},

	/* Lobby */
	noGame: {
		se: 'Det finns inget spel som hör till denna URL',
		en: 'There is no game connected to this URL',
	},
	playerDiffGameConfirmId: {
		se: `Det ser ut som om ditt anställningsnummer %userId% hör till %gameName%.
			<br /><br />Är du säker på att det är ditt anställningsnummer?`,
		en: `It looks like you employee id %userId% is connected to %gameName%.
			<br /><br />Are you sure it is your employee id?`,
	},
	noLogOut: {
		se: 'Nej, logga ut',
		en: 'No, log out',
	},
	playerDiffGame: {
		se: `Du är för närvarande ansluten till %gameName%.
			<br /><br />Du kan bara vara ansluten till ett spel åt gången.`,
		en: `You are currently connected to %gameName%.
			<br /><br />You can only be connected to one game at a time.`,
	},
	gameDeleted: {
		se: '??? (raderat)',
		en: '??? (deleted)',
	},
	goToGameBtn: {
		se: 'Gå till %gameName%',
		en: 'Go to %gameName%',
	},
	switchGameBtn: {
		se: 'Byt till %gameName%',
		en: 'Switch to %gameName%',
	},
	switchGameWarning: {
		se: `Du kan bara vara ansluten till ett spel åt gången.
			<br /><br />Om du byter till %gameName2% går alla framsteg i %gameName1% förlorade.
			<br /><br />Är du säker på att du vill byta till %gameName2%?`,
		en: `You can only be connected to one game at a time.
			<br /><br />If you switch to %gameName2% all your progress in %gameName1% will be lost.
			<br /><br />Are you sure you want to switch to %gameName2%?`,
	},
	cancelBtn: {
		se: 'Avbryt',
		en: 'Cancel',
	},
	confirmBtn: {
		se: 'Ja, byt spel',
		en: 'Yes, switch game',
	},

	/* Choose language */
	chooseLanguage: {
		se: 'Välj språk',
		en: 'Choose language',
	},

	/* Player info */
	playerInfo: {
		se: 'Ange ditt namn och din e-post för att spela.',
		en: 'Enter your name and e-mail to player.',
	},
	playerInfoName: {
		se: 'Namn',
		en: 'Name',
	},
	playerInfoEmail: {
		se: 'E-post',
		en: 'E-mail',
	},

	/* Module overview */
	reset: {
		se: 'Återställ',
		en: 'Reset',
	},

	moduleOverview: {
		se: 'Modulöversikt',
		en: 'Module overview',
	},
	employeeNumber: {
		se: 'Anställningsnr.',
		en: 'Employee no.',
	},

	/* Module - general */
	continue: {
		se: 'Nästa',
		en: 'Next',
	},
	ok: {
		se: 'OK',
		en: 'OK',
	},
	choose: {
		se: 'Välj',
		en: 'Choose',
	},
	option: {
		se: 'alternativ',
		en: 'option',
	},
	options: {
		se: 'alternativ',
		en: 'options',
	},
	finishBtn: {
		se: 'Klar',
		en: 'Finish',
	},

	/* Module - results */
	results: {
		se: 'resultat',
		en: 'results',
	},
	stars: {
		se: 'Stjärnor',
		en: 'Stars',
	},
	points: {
		se: 'Poäng',
		en: 'Points',
	},
	timeSpent: {
		se: 'Spenderad tid',
		en: 'Time spent',
	},
	avgErrors: {
		se: 'Genomsnittligt antal fel per uppgift',
		en: 'Average mistakes per task',
	},
	noErrorsTasks: {
		se: 'Antal uppgifter som lösts utan fel',
		en: 'Number of tasks solved without mistakes',
	},
	backToModuleOverview: {
		se: 'Tillbaka till modulöversikten',
		en: 'Back to module overview',
	},
	tryAgain: {
		se: 'Försök igen',
		en: 'Try again',
	},
	confirmTryAgainPopup: {
		title: {
			se: 'Försök igen',
			en: 'Try again'	,
		},
		text: {
			se: 'Vill du slå din egen poäng? Du kan återställa modulen och spela igen.',
			en: 'Want to try to beat your own score? You can reset the module and play again.',
		},
		resetBtn: {
			se: 'Återställ',
			en: 'Reset',
		},
		cancelBtn: {
			se: 'Avbryt',
			en: 'Cancel',
		}
	},

	/* Module - survey task */
	surveyChooseXOptions: {
		se: 'Välj %X%',
		en: 'Choose %X%',
	},
	surveyChooseMaxXOptions: {
		se: 'Välj högst %X%',
		en: 'Choose up to %X%',
	},

	/* Module - order task */
	orderPopup: {
		title: {
			se: 'Du fick %correctAnswers%/%totalAnswers%.',
			en: `You got %correctAnswers%/%totalAnswers%.`,
		},
		text: {
			se: 'Försök igen!',
			en: `Try again!`,
		}
	},

	/* Module - spot errors task */
	spotErrorsPopup: {
		title1: {
			se: 'Hoppsan!',
			en: 'Oops!',
		},
		text1: {
			se: 'Du hittade bara %spottedErrors% av %numberOfErrors% fel.',
			en: 'You only %spottedErrors% of %numberOfErrors% errors.',
		},
		title2: {
			se: 'Inte illa!',
			en: 'Not bad!',
		},
		text2: {
			se: 'Du hittade %spottedErrors% av %numberOfErrors% fel.',
			en: 'You spotted %spottedErrors% of %numberOfErrors% errors.',
		},
		title3: {
			se: 'Bra jobbat!',
			en: 'Well done!',
		},
		text3: {
			se: 'Du hittade %spottedErrors% av %numberOfErrors% fel.',
			en: 'You spotted %spottedErrors% of %numberOfErrors% errors.',
		},
	},

	/* Module - talk to facilitator popup */
	talkToFacilitatorPopup: {
		text: {
			se: 'Du har gjort för många fel i den här modulen. Prata med kursledaren om hur du ska gå vidare.',
			en: 'You have had too many errors in this module. Talk to your facilitator about how to proceed.',
		},
		okBtn: {
			se: 'OK',
			en: 'Ok',
		}
	},

	/* Module - locked popup */
	moduleLockedPopup: {
		text: {
			se: `Du har gjort för många fel i den här modulen. Prata med din kontaktperson om hur du ska gå vidare.
				<br /><br />Den här modulen är låst till %date%`,
			en: `You have had too many errors in this module. Talk to your facilitator about how to proceed. 
				<br /><br />This module will be locked until %date%`,
		},
		okBtn: {
			se: 'OK',
			en: 'Ok',
		}
	}
};

module.exports = {
	playerUiTexts
};