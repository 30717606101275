const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-retraining-welcome',
		showProgressBar: false,
		background: 'production',
		characters: ['manager pose-2 coat-2 hairnet-1'],
		text: {
			se: `Välkommen till den andra delen av hygienutbildningen. Nu ska vi prata om hygienen inne i produktionsområdena.`,
			en: `Welcome to the second part of the hygiene training. Now we are going to talk about hygiene inside the production areas. `,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-stars',
		showProgressBar: true,
		background: 'production',
		characters: ['manager pose-1 coat-1 hairnet-1'],
		text: {
			se: `Då börjar vi! Kom ihåg, du måste få tre stjärnor för att slutföra modulen.`,
			en: `Let's start! Remember, you must get three stars to complete the module. `,
		}
	}),
	Object.assign({}, taskTemplates.sortTemplate.mainTemplate, {
		taskId: 's1-m4-sort-zones',
		background: 'production',
		text: {
			se: `Som du vet har vi tre olika zoner på Almondy: vit, grå och röd. Vad gäller i vilka zoner? Para ihop följande beskrivningar i rätt zoner.`,
			en: `As you know, we have three different zones at Almondy; white, grey, and red. What apply to which zones? Sort the following into the correct zones. `
		},
		categories: [
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 1,
				title: {
					se: `Vit zon`,
					en: `White zone`
				}
			}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 2,
				title: {
					se: `Grå zon`,
					en: `Grey zone`
				}
			}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 3,
				title: {
					se: `Röd zon`,
					en: `Red zone`
				}
			}),
		],
		items: [
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 1,
				text: {
					se: `Öppen, oförpackad produkt`,
					en: `Open, non-packaged goods`
				}, 
				categoryIds: [1]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 2,
				text: {
					se: `Sluss mellan lager och produktion (låg risk)`,
					en: `Sluice between warehouse and production (low risk)`
				}, 
				categoryIds: [2]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 3, 
				text: {
					se: `High care - högsta hygienstandard`,
					en: `High care - highest hygiene standards`
				}, 
				categoryIds: [1]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 4,
				text: {
					se: `Lager - endast förpackade råvaror och produkter`,
					en: `Enclosed product area`
				}, 
				categoryIds: [3]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-hygiene-slide-presentation-1',
		layout: 's1-m2-presentation',
		textType: 'slide',
		showProgressBar: true,
		background: 'production',
		characters: ['manager pose-1 coat-1 hairnet-1'],
		title: {
			se: `Vit zon`,
			en: `White Zone`
		},
		text: {
			se: `<br />- Hantering av öppna, oförpackade produkter<br />
				<br />- Strikta regler för hygien<br />
				<br />- T.ex. produktionshallen, entresol, ugnsavdelningen och lilla frysen`,
			en: `<br />- Handling of open, unpackaged products<br />
				<br />- Strict rules for hygiene<br />
				<br />- Eg. production areas, mezzanine, oven section, and small freezer`,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-hygiene-slide-presentation-2',
		layout: 's1-m2-presentation',
		textType: 'slide',
		showProgressBar: true,
		background: 'production',
		characters: ['manager pose-1 coat-1 hairnet-1'],
		title: {
			se: `Grå zon`,
			en: `Grey Zone`
		},
		text: {
			se: `<br />- Slussar mellan röd och vit zon<br />
				<br />- Delvis öppna produkter<br />
				<br />- Blandning av ingredienser<br />
				<br />- Samma hygienregler som i vit zon vid hantering av livsmedel`,
			en: `<br />- Gateways between red and white zones<br />
				<br />- Partially open products<br />
				<br />- Mixing of ingredients<br />
				<br />- Same hygiene rules as in white zones when handling foodstuffs`,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-hygiene-slide-presentation-3',
		layout: 's1-m2-presentation',
		textType: 'slide',
		showProgressBar: true,
		background: 'production',
		characters: ['manager pose-1 coat-1 hairnet-1'],
		title: {
			se: `Röd zon`,
			en: `Red Zone`
		},
		text: {
			se: `<br />- Endast förpackade produkter och råvaror<br />
				<br />- T.ex. lager, stora frysen och verkstad`,
			en: `<br />- Only enclosed products<br />
				<br />- Eg. warehouse, big freezer, and workshop`,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-hygiene-zones',
		showProgressBar: true,
		background: 'production',
		characters: ['manager pose-2 coat-2 hairnet-1'],
		text: {
			se: `Hygienen är viktig i alla zonerna, men kraven är högre i vit zon och i grå zon när man hanterar råvaror.`,
			en: `Hygiene is important in all zones, but the demands are higher in the white zone, and in the part of the grey zone when handling ingredients. `,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-mostly-white-zone',
		showProgressBar: true,
		background: 'production',
		characters: ['manager pose-2 coat-2 hairnet-1'],
		text: {
			se: `I den här modulen kommer vi mestadels att vara i vit zon. Tänk på att samma regler gäller när man hanterar öppna råvaror i grå zon.`,
			en: `In this module we'll mostly be in a white zone. But remember that the same rules apply when working with non-packaged materials in the grey zone. `,
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-aware-hygiene',
		background: 'production',
		shuffleOptions: false,
		showRequiredNumberOfAnswers: false,
		text: {
			se: `Varför är det så viktigt med god hygien i produktionen?`,
			en: `Why is hygiene so important in production areas? `
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					se: `På grund av lagkrav, kundkrav och standardkrav`,
					en: `Because of legal, customer, and standard requirements`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					se: `Eftersom dålig hygien kan göra folk sjuka`,
					en: `Because poor hygiene can make people sick`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					se: `För att tillverka produkter av hög kvalitet`,
					en: `To produce high-quality products`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					se: `Av alla ovanstående skäl`,
					en: `For all the reasons above`,
				},
				isCorrect: true
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-next-image-spot',
		showProgressBar: true,
		background: 'production',
		characters: ['manager pose-2 coat-2 hairnet-1'],
		text: {
			se: `Nu tar vi en närmare titt på produktionen. Kan du upptäcka de saker som kan utgöra en risk när det gäller hygienen på nästa bild?`,
			en: `Let's take a closer look at the production. On the next screen, see if you can spot the things that can be a risk when it comes to hygiene. `,
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m4-spot-errors-production-errors',
		layout: 's1-m2-production-errors',
		background: 'production',
		backgroundModifier: 'no-machine',
		text: {
			se: `Klicka på de 6 riskerna. Klicka på OK när du är klar eller om du inte kan hitta alla.`,
			en: `Click on the 6 issues. Click OK when you are ready or if you can't find them all.`
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'open-box', // Open box with product placed on floor
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'tape' // Piece of tape on the belt flapping
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'person', // Person at work station with broken glove on one hand
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'white-shovel', // White showel on red rack with red tools
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'cake', // Cake with no packagin on belt
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'shirt', // Private t-shirt visible under white coat
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-white-shovel-red-rack',
		background: 'production',
		text: {
			se: `I föregående uppgift var den vita spaden på den röda upphängningen en risk. Varför då?`,
			en: `In the previous task, the white shovel on the red rack was a hygiene risk. Why is that?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					se: `Att blanda ihop redskap kan orsaka kontamination`,
					en: `Mixing tools can cause contamination`,
				},
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								se: `Precis! Det är därför man alltid måste förvara redskapen på rätt plats.`,
								en: `Exactly! That's why you should always store tools in the right places.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					se: `Det ser inte trevligt ut`,
					en: `It doesn't look nice`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					se: `Den borde ha legat på golvet istället`,
					en: `It should have been on the floor instead`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					se: `Den borde ha varit gul.`,
					en: `It should have been yellow.`,
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								se: `Absolut inte. Gula redskap används på lagret, aldrig i produktionsområden.`,
								en: `Absolutely not. Yellow tools are used in the warehouse, never in production areas.`
							}
						})
					})
				],
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-previous-task-missing-glove',
		showProgressBar: true,
		background: 'production',
		characters: ['manager pose-2 coat-2 hairnet-1'],
		text: {
			se: `Personen i föregående uppgift hade en trasig handske. Vi har särskilda rutiner för handskar, så vi kan väl ta en närmare titt på dem.`,
			en: `The person in the previous task had a broken glove. We have special rules for gloves, so let's do a deep dive into those. `,
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-what-rules-for-gloves',
		background: 'production',
		text: {
			se: `Vilka regler gäller för användning av handskar?`,
			en: `What rules apply for using gloves?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					se: `Tvätta händerna innan du tar på dig handskar`,
					en: `Wash hands before putting on gloves`,
				},
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								se: `Ja! Om man inte tvättar händerna före användning så är inte heller handsken ren.`,
								en: `Yes! If you don't wash your hands before use, then the gloves won't be clean either.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					se: `Vid rotation ska handskarna bytas och händerna tvättas.`,
					en: `After rotation, change gloves and wash hands`,
				},
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								se: `Precis. Handskarna gör händerna varma och fuktiga, vilket är en bra miljö för bakterier.`,
								en: `Exactly. Gloves make your hands warm and moist which is a good environment for bacteria.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					se: `Handskar får endast användas på vissa stationer, t.ex. toppdekor och facket (avsyning av tårtor)`,
					en: `Gloves may only be used at certain stations, e.g. top decor and tray (viewing pies)`,
				},
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					se: `Används alltid i vit zon`,
					en: `Always use in white zone`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: {
					se: `Används alltid i röd zon.`,
					en: `Always use in red zone`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: {
					se: `Använd när du arbetar i ugnen`,
					en: `Use when working in the oven area`,
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-when-change-gloves',
		background: 'production',
		text: {
			se: `När bör man byta handskar?`,
			en: `When should you change your gloves?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					se: `Vid byte av arbetsstation`,
					en: `When changing work station`,
				},
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					se: `Efter max 30 minuters användning`,
					en: `After no more than 30 minutes of use`,
				},
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					se: `Om de har kontaminerats`,
					en: `If they've been contaminated`,
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								se: `Ja, till exempel om du rört vid golvet.`,
								en: `Yes. For instance if you've touched the floor`
							}
						})
					})
				],
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					se: `Efter 1 timmes användning`,
					en: `After 1 hour of use`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: {
					se: `När arbetsskiftet är slut`,
					en: `At the end of the shift`,
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 's1-m4-order-hygiene-sluice',
		background: 'production',
		text: {
			se: `Vilken ordning är rätt när man ska ta på sig handskar? Placera följande i rätt ordning. Klicka på OK när du är klar.`,
			en: `What is the correct order of operations for using gloves? Place the actions below in the right order. Click OK when you're ready.`
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				text: {
					se: `Tvätta händerna`,
					en: `Wash hands`
				},
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2,
				text: {
					se: `Ta handskar och kontrollera att de är hela`,
					en: `Take gloves and control that they aren't broken`
				},
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				text: {
					se: `Skriv antal, tid och din signatur på checklistan`,
					en: `Register number and time on checklist, then sign`
				},
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4,
				text: {
					se: `Arbeta`,
					en: `Work`
				},
				correctOrderNumbers: [4]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 5,
				text: {
					se: `Kontrollera att handskarna är hela när du tar av dig dem. `,
					en: `Control gloves`
				},
				correctOrderNumbers: [5]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 6,
				text: {
					se: `Markera godkänd/ej godkänd på checklistan innan du slänger handskarna.`,
					en: `Mark approved/not approved on checklist before tossing gloves`
				},
				correctOrderNumbers: [6]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-count-control-gloves',
		showProgressBar: true,
		background: 'production',
		characters: ['manager pose-1 coat-1 hairnet-1'],
		text: {
			se: `Vi räknar och kontrollerar handskarna för att se till att inga handskar eller delar av handskar hamnar i produkterna.`,
			en: `We count and control gloves to make sure no gloves or pieces of gloves end up in our products. `,
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-what-gloves-not-approved',
		layout: 's1-m2-what-gloves-not-approved',
		subtype: 'images',
		background: 'production',
		text: {
			se: `Vilka handskar ska inte godkännas efter användning?`,
			en: `What gloves should not be approved after use? `
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Gloves palms up, ok.
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Gloves palms down, ok.
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Gloves, one with a bit of fingertip missing
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Gloves, one with small hole between thumb and other fingers
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // Glove with a long tear on the palm. No parts missing.
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-mark-gloves',
		showProgressBar: true,
		background: 'production',
		characters: ['manager pose-2 coat-2 hairnet-1'],
		text: {
			se: `Om någon bit av handsken saknas är den inte godkänd. Notera tiden på checklistan och säg till din förman som gör en riskbedömning tillsammans med dig. Vid behov vidtas åtgärder och eventuellt spärras produkt. `,
			en: `If a part of the glove is  missing, it's not approved. Note the time on the checklist and tell your immediate manage who will make a risk assesment with you. If needed, measures are taken and product is possibly blocked. `,
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-process-controlling-used-gloves',
		background: 'production',
		text: {
			se: `Du håller just på att kontrollera ett par handskar som du använt. En kollega ber dig att skriva upp hennes handskar som godkända – hon kontrollerade dem nyss, men glömde skriva ner det i checklistan. Får du göra det?`,
			en: `You are in the process of controlling some gloves you've used. A colleague asks if you can note her gloves as approved - she just did it, but forgot to write it in the checklist. Can you do that?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					se: `Nej`,
					en: `No`,
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								se: `Precis. Godkänn bara kontroller du har gjort själv.`,
								en: `Exactly. Only sign off on controls you've made yourself.`
							}
						})
					})
				],
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					se: `Ja`,
					en: `Yes`,
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-gloves-contaminated-products',
		showProgressBar: true,
		background: 'production',
		characters: ['manager pose-2 coat-2 hairnet-1'],
		text: {
			se: `Vi använder handskar bland annat för att förhindra att produkterna kontamineras. Nu ska vi prata lite om kontaminering.`,
			en: `We use gloves to, among other things, keep our products from being contaminated. Let's talk about contamination. `,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-what-is-contamination',
		showProgressBar: true,
		background: 'production',
		characters: ['manager pose-1 coat-1 hairnet-1'],
		text: {
			se: `Kontaminering (förorening) är något som inte ska vara i produkterna, t.ex. bakterier, främmande föremål, kemikalier eller oönskade allergener`,
			en: `Contamination is when things get into the products that aren't supposed to be there. This can be bacterias, foreign bodies, chemicals, or unwanted allergens. `,
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-why-contamination',
		background: 'production',
		text: {
			se: `Våra produkter kan kontamineras på olika sätt. Varför är kontaminering ett problem?`,
			en: `Our products can become contaminated in different ways. Why is contamination a problem?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					se: `Det kan vara farligt för konsumenten`,
					en: `It can be dangerous to the consumer`,
				},
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					se: `Det kan förstöra smaken`,
					en: `It can ruin the taste`,
				},
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					se: `Det kan göra kunderna missnöjda`,
					en: `It can cause unhappy customers`,
				},
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					se: `Det släpper ut mer koldioxid`,
					en: `It emits more CO2`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: {
					se: `Kontamination är bara ett problem om kvalitetsavdelningen ser det`,
					en: `Contamination is only an issue if Quality sees it`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: {
					se: `Det är olagligt`,
					en: `It's illegal`,
				},
				isCorrect: true,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-three-allergens',
		layout: 's1-m2-three-allergens',
		subtype: 'images',
		background: 'production',
		text: {
			se: `Kontaminering kan även orsaka problem för personer med allergier. Klicka på tre allergener som vi hanterar på Almondy.`,
			en: `Contamination can also cause problems for people with allergies. Click on three allergens which we handle at Almondy. `
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-forbidden-allergen',
		background: 'production',
		text: {
			se: `Vissa allergener finns i våra produkter, t.ex. mjölk, ägg och mandel. Men vilken allergen är INTE tillåten alls i produktionen på Almondy?`,
			en: `Some allergens are in our products, eg. milk, eggs, and almonds. But what allergen is NOT allowed at Almondy at all?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					se: `Gluten`,
					en: `Gluten`,
				},
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					se: `Jordnötter`,
					en: `Peanuts`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					se: `Soja`,
					en: `Soy`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					se: `Grädde`,
					en: `Cream`,
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 's1-m4-order-broken-lamp',
		background: 'production',
		text: {
			se: `En lampa har gått sönder i produktionsområdet, och det finns risk att glas har hamnat i produkten. Vad bör du göra? Placera följande handlingar i rätt ordning.`,
			en: `A lamp gets broken in a production area, and there's a risk that glass have gotten into the product. What should you do? Place the actions below in the right order. `
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				text: {
					se: `Stoppa produktionen`,
					en: `Stop production`
				},
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2,
				text: {
					se: `Tillkalla förman`,
					en: `Call for my immediate manager`
				},
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				text: {
					se: `Städa området`,
					en: `Clean the area`
				},
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4,
				text: {
					se: `Byta kläder`,
					en: `Change clothes`
				},
				correctOrderNumbers: [4]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 5,
				text: {
					se: `Invänta klartecken från förman`,
					en: `Await release from manager`
				},
				correctOrderNumbers: [5]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-good-hygiene',
		showProgressBar: true,
		background: 'production',
		characters: ['manager pose-1 coat-1 hairnet-1'],
		text: {
			se: `God hygien och därmed hög kvalitet börjar hos dig. Se om du kan upptäcka felen på nästa bild.`,
			en: `Good hygiene and thus high quality begins with you. See if you can spot the issues on the next image. `,
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m4-spot-errors-production-errors-2',
		layout: 's1-m2-production-errors-2',
		background: 'production',
		backgroundModifier: 'no-machine',
		text: {
			se: `Klicka på de 4 felen. Klicka på OK när du är klar eller om du inte kan hitta alla.`,
			en: `Click on the 4 issues. Click OK when you are ready or if you can't find them all. `
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'open-gate', // Gate left half open
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								se: `Ja, du bör stänga denna för att undvika att skadedjur tar sig in.`,
								en: `Yes, you should close this to avoid pests getting inside.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'glass-water', // Glass of water
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								se: `Glas är inte tillåtet i produktionen. Du får endast dricka vatten i närpausrummet.`,
								en: `No glass is allowed in production areas.You are only allowed to drink water in the break room. `
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'spill', // Spill on the floor
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'soap', // soap bubbles on the belt
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-why-soap-bad',
		background: 'production',
		text: {
			se: `Tvålen på bandet var ett fel i föregående uppgift. Varför då?`,
			en: `The soap on the belt was a problem in the previous task. Why?`
		},
		options: [			 		
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					se: `Den kan orsaka kemisk kontaminering`,
					en: `It can cause chemical contamination`,
				},
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					se: `Vi vill inte att vår produkt ska vara ren`,
					en: `We don't want our product to be clean`,
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								se: `Jo, det gör vi! Men inte med hjälp av tvål.`,
								en: `Yes we do! But not by using soap.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					se: `Vi kan halka på den`,
					en: `We can slip in it`,
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								se: `Bara om ni går på bandet, och det ska ni verkligen inte göra.`,
								en: `Only if you walk on the belt, and you really shouldn't.`
							}
						})
					})
				],
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-gate-was-open',
		showProgressBar: true,
		background: 'production',
		characters: ['manager pose-2 coat-2 hairnet-1'],
		text: {
			se: `I den föregående uppgiften stod en port öppen. Detta kan vara en hygienrisk risk eftersom skadedjur kan ta sig in i byggnaden. Det vill vi verkligen inte.`,
			en: `In a previous task, the gate was open. That can be a hygiene risk since it allows pests to enter our building. We really don't want that. `,
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-mouse',
		background: 'production',
		text: {
			se: `Du får syn på en mus på golvet i produktionsområdet. Vad ska du göra?`,
			en: `You spot a mouse on the floor in the production area. What must you do?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					se: `Försöka hålla ett öga på musen medan jag kallar på hjälp`,
					en: `Try to keep an eye on the mouse while I call for help`,
				},
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					se: `Försöka fånga musen och kalla på hjälp om jag inte lyckas`,
					en: `Try to catch the mouse and then call for help if unsuccessful`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					se: `Berätta för min förman vid arbetspassets slut`,
					en: `Tell my immediate manager at the end of the shift`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					se: `Hämta en katt`,
					en: `Get a cat`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: {
					se: `Ge den en paj`,
					en: `Give it a pie`,
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-metal-detector',
		showProgressBar: true,
		background: 'production',
		characters: ['manager pose-2 coat-2 hairnet-1'],
		text: {
			se: `Vi har metalldetektorer för att säkerställa att inga produkter som innehåller metall når kunderna.`,
			en: `We use a metal detector to ensure that no product gets to our customers with metal objects inside. `,
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-when-metal-test',
		background: 'production',
		text: {
			se: `Metalldetektorn ska kontrolleras vid vissa tidpunkter. När då?`,
			en: `The metal detector must be tested at certain times. When is that?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					se: `Varje hel timme`,
					en: `At the top of every hour`,
				},
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					se: `När produktionen startas`,
					en: `When starting production`,
				},
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					se: `När produktionen avslutas`,
					en: `When ending production`,
				},
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					se: `Var 30:e minut`,
					en: `Every 30 minutes`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: {
					se: `När jag byter handskar`,
					en: `When I change my gloves`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				isCorrect: true,
				text: {
					se: `När produktionen startar efter ett längre produktionsstopp`,
					en: `When production starts after a long production stop`,
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-when-test-metal-detector',
		showProgressBar: true,
		background: 'production',
		characters: ['manager pose-2 coat-2 hairnet-1'],
		text: {
			se: `Om du måste testa metalldetektorn när det är produktionsstopp ska du använda tomma kapslar. Testa sedan metalldetektorn igen med produkt så snart produktionen är igång igen.`,
			en: `If you have to test the metal detector during a production stoppage, use empty boxes. Then test it again with products as soon as production resumes. `,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-almost-done-module',
		showProgressBar: true,
		background: 'production',
		characters: ['manager pose-1 coat-1 hairnet-1'],
		text: {
			se: `Nu är du nästan klar med modulen. Bara några frågor till.`,
			en: `You are now almost through the module. Only a few more questions. `,
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-at-least-once',
		background: 'production',
		text: {
			se: `Håll ordning och reda på din arbetsstation! Se till att tömma sopsäckar innan rotationen. Vid behov ska golvet städas. Vilka är de två viktigaste anledningarna till att golvet måste hållas rent?`,
			en: `Keep you work station neat and tidy. Make sure you empty garbage bags prior to the rotation. If needed, clean the floor. What the two most important reasons to keep the floor clean?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					se: `Smutsiga golv ökar risken för kontaminering`,
					en: `Dirty floors increase the risk of contamination`,
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								se: `Helt rätt! Se därför till att alltid spola vatten med lågt tryck för att undvika att det skvätter på utrustning och tårtor.`,
								en: `Yes! So make sure to always clean the floor a lov pressure to avoid staining equipment or products. `
							}
						})
					})
				],
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					se: `Vatten på golvet är en bra grogrund för bakterier`,
					en: `Water on the floor can cause bacterial growth`,
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								se: `Exakt! Om du har spolat golvet måste du ALLTID skapa undan vattnet efter dig.`,
								en: `Exactly! So if you've hosed the floor, always wipe off the water afterwards. `
							}
						})
					})
				],
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					se: `Det ser finare ut`,
					en: `It looks nicer`,
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								se: `Det gör det, men det är inte det viktiga.`,
								en: `It does, but that is not the important part.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					se: `Rena golv är bättre att göra armhävningar på`,
					en: `Clean floors are better for doing push-ups`,
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								se: `Varför skulle du göra armhävningar? Dessutom måste du tvätta händerna om du vidrört golvet.`,
								en: `Why would you do push-ups? Also, you should definitely wash your hands if you touch the floor.`
							}
						})
					})
				],
			}),
		]
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 's1-m4-order-when-cleaning-floor',
		background: 'production',
		text: {
			se: `Vilken är rätt ordning när man ska rengöra golvet?`,
			en: `When cleaning the floor, what is the correct order of operations?`
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				text: {
					se: `Skyffla upp produkter från golvet och släng i en gul balja`,
					en: `Sweep up leftover product and toss in yellow tub`
				},
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2,
				text: {
					se: `Skrapa ner mindre produktrester i brunnen`,
					en: `Scrape remaining product into drain`
				},
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				text: {
					se: `Vid behov spola golvet försiktigt med vatten (lågt vattentryck!)`,
					en: `If needed, rinse the floor at low pressure`
				},
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4,
				text: {
					se: `Skrapa undan vattnet i brunn eller golvränna.`,
					en: `Mop up the water`
				},
				correctOrderNumbers: [4]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 5,
				text: {
					se: `Spola av skyffel och skrapa och häng tillbaka på sin plats. `,
					en: `Clean and return tools`
				},
				correctOrderNumbers: [5]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-almost-done-module',
		showProgressBar: true,
		background: 'production',
		characters: ['manager pose-2 coat-2 hairnet-1'],
		text: {
			se: `Till sist har vi fyra frågor om Almondys kultur för livsmedelssäkerhet. Här är inga svar rätt eller fel. Du får inga poäng för de här svaren, och dina svar är anonyma.`,
			en: `Finally, we have four questions about Almondy's food safety culture. There are no right or wrong answers here. You also don't get points for answering, and your answers are anonymous. `,
		}
	}),

	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m4-survey-1',
		shuffleOptions: false,
		text: {
			se: `<b>Hur mycket instämmer du i följande?</b>
			<br /><br />
			Jag får och måste agera om jag ser något som kan äventyra livsmedelssäkerheten.`,
			en: `<b>How strongly do you agree with the following?</b>
			<br /><br />
			I'm allowed and required to act if I see something that can jeopardise food safety.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					se: `Instämmer verkligen`,
					en: `Strongly agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					se: `Instämmer`,
					en: `Agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					se: `Varken eller`,
					en: `Neither agree nor disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					se: `Instämmer inte`,
					en: `Disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					se: `Instämmer verkligen inte`,
					en: `Strongly disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					se: `Vet inte`,
					en: `Don't know`
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m4-survey-2',
		shuffleOptions: false,
		text: {
			se: `<b>Hur mycket instämmer du i följande?</b>
			<br /><br />
			Jag reagerar om jag ser att andra inte följer våra hygienregler.`,
			en: `<b>How strongly do you agree with the following?</b>
			<br /><br />
			I react if I see others who do not follow our hygiene rules.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					se: `Instämmer verkligen`,
					en: `Strongly agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					se: `Instämmer`,
					en: `Agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					se: `Varken eller`,
					en: `Neither agree nor disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					se: `Instämmer inte`,
					en: `Disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					se: `Instämmer verkligen inte`,
					en: `Strongly disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					se: `Vet inte`,
					en: `Don't know`
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m4-survey-3',
		shuffleOptions: false,
		text: {
			se: `<b>Hur mycket instämmer du i följande?</b>
			<br /><br />
			Om jag ser ett problem med livsmedelssäkerheten löser jag det eller informerar min förman eller motsvarande.`,
			en: `<b>How strongly do you agree with the following?</b>
			<br /><br />
			If I see a food safety problem, I solve it or inform my immediate manager.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					se: `Instämmer verkligen`,
					en: `Strongly agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					se: `Instämmer`,
					en: `Agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					se: `Varken eller`,
					en: `Neither agree nor disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					se: `Instämmer inte`,
					en: `Disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					se: `Instämmer verkligen inte`,
					en: `Strongly disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					se: `Vet inte`,
					en: `Don't know`
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m4-survey-4',
		shuffleOptions: false,
		text: {
			se: `<b>Hur starkt instämmer du i följande?</b>
			<br /><br />
			Om jag rapporterar en risk med livsmedelssäkerheten kommer åtgärder att vidtas.`,
			en: `<b>How strongly do you agree with the following?</b>
			<br /><br />
			If I make a report about a risk to food safety, it will be acted upon.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					se: `Instämmer verkligen`,
					en: `Strongly agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					se: `Instämmer`,
					en: `Agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					se: `Varken eller`,
					en: `Neither agree nor disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					se: `Instämmer inte`,
					en: `Disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					se: `Instämmer verkligen inte`,
					en: `Strongly disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					se: `Vet inte`,
					en: `Don't know`
				}
			}),
		]
	}),
	
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-done-module',
		showProgressBar: true,
		background: 'production',
		characters: ['manager pose-2 coat-2 hairnet-1'],
		text: {
			se: `Det var det, nu är du klar med den andra modulen. Om du har tre stjärnor är du klar med hygienutbildningen, annars måste du göra om modulen.`,
			en: `That's it, you are now through the second module. If you have three stars, you have completed the hygiene training. If not, you have to retake the module. `,
		}
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 's1-m4-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};