// NOTE: remember to update the UI-texts file on the drive, if you update this file */

const generalUiTexts = {
	hygieneTraining: {
		se: 'Hygienutbildning',
		en: 'Hygiene training',
	},
	start: {
		se: 'Starta',
		en: 'Start',
	},
	loading: {
		se: 'Laddar',
		en: 'Loading',
	}
};


module.exports = {
	generalUiTexts
};