const languagesData = [
	{
		id: 'se',
		title: {
			se: 'Svenska', 
			en: 'Svenska', // language names are always in their own language
		},
		localization: 'sv-SE'
	},
	{
		id: 'en',
		title: {
			se: 'English',
			en: 'English',
		},
		localization: 'en-GB'
	},
];

export {languagesData};