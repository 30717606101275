import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

/* Log environment */
let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
console.log('ENVIRONMENT: ', env);

let firebaseConfig = {};

/* Connect to firebase project test */
if (env === 'development' || env === 'test' || env === 'demo') {
	firebaseConfig = {
		apiKey: 'AIzaSyB-C_ls_9hUcKyBCnoLDpkGLcR3tgX9ADw',
		authDomain: 'cgl-almondy-test.firebaseapp.com',
		projectId: 'cgl-almondy-test',
		storageBucket: 'cgl-almondy-test.appspot.com',
		messagingSenderId: '46228036099',
		appId: '1:46228036099:web:e8663f8356760a6de34c36'
	};	
}

/* Connect to firebase: production */
if (env === 'production') {
	firebaseConfig = {
		apiKey: 'AIzaSyB3lpXm4aI69oTPXr9urguX0VWyEOfDOzY',
		authDomain: 'cgl-almondy-production.firebaseapp.com',
		projectId: 'cgl-almondy-production',
		storageBucket: 'cgl-almondy-production.appspot.com',
		messagingSenderId: '5998528349',
		appId: '1:5998528349:web:884a69f9481541bbb28ab4'
	};
}

/* Initialize firebase */
firebase.initializeApp(firebaseConfig);

export default firebase.firestore;