// NOTE: remember to update the UI-texts file on the drive, if you update this file */

const facilitatorUiTexts = {
	admin: {
		se: 'Admin',
		en: 'Admin',
	},
	loggedInAs: {
		se: 'Inloggad som',
		en: 'Logged in as',
	},
	noGames: {
		se: 'Du är inte registrerad som kontaktperson i något spel.',
		en: 'You are not registered as a facilitator of any games.',
	},

	chooseGame: {
		se: 'Välj spel',
		en: 'Choose game',
	},
	gameName: {
		se: 'Spelnamn',
		en: 'Game name',
	},
	gameUrl: {
		se: 'Spel-URL',
		en: 'Game URL',
	},
	url: {
		se: 'URL',
		en: 'URL',
	},
	created: {
		se: 'Skapat',
		en: 'Created',
	},
	scenario: {
		se: 'Scenario',
		en: 'Scenario',
	},
	choose: {
		se: 'Välj',
		en: 'Choose',
	},
	backToChooseGames: {
		se: 'Välj ett annat spel',
		en: 'Choose another game',
	},

	showInactivePlayers: {
		se: 'Visa inaktiva spelare',
		en: 'Show inactive players',
	},
	employeeNumber: {
		se: 'Anställningsnr',
		en: 'Employee no.',
	},
	email: {
		se: 'E-post',
		en: 'E-mail',
	},
	name: {
		se: 'Namn',
		en: 'Name'
	},
	lastLogin: {
		se: 'Senaste inloggning',
		en: 'Last login',
	},
	status: {
		se: 'Status',
		en: 'Status',
	},
	active: {
		se: 'Aktiv',
		en: 'Active',
	},
	inactive: {
		se: 'Inaktiv',
		en: 'Inactive',
	},
	deactivateBtn: {
		se: 'Inaktivera',
		en: 'Deactivate',
	},
	reactivateBtn: {
		se: 'Återaktivera',
		en: 'Reactivate',
	},
	lastCompletedModule: {
		se: 'Senaste slutförda modul',
		en: 'Last completed module',
	},
	moduleGroup: {
		se: 'Modulgrupp',
		en: 'Module group',
	},
	module: {
		se: 'Modul',
		en: 'Module',
	},
	completion: {
		se: 'Framsteg',
		en: 'Progress',
	},
	timeSpent: {
		se: 'Spenderad tid',
		en: 'Time spent',
	},
	minutes: {
		se: 'minuter',
		en: 'minutes',
	},
	avgMistakes: {
		se: 'Antal fel (genomsnitt)',
		en: 'Number of errors (avg)',
	},
	task: {
		se: 'Uppgift',
		en: 'Task',
	},
	type: {
		se: 'Typ',
		en: 'Type',
	},
	mistakes: {
		se: 'Misstag (genomsnitt)',
		en: 'Mistakes (avg)',
	},
	attempts: {
		se: 'Försök',
		en: 'Attempts',
	},
	players: {
		se: 'Spelare',
		en: 'Players',
	},
	viewSurveys: {
		se: 'Visa enkäter',
		en: 'View surveys',
	},
	surveys: {
		se: 'Enkäter',
		en: 'Surveys',
	},
	question: {
		se: 'Fråga',
		en: 'Question'
	},
	noEnoughSurveyResponses: {
		se: 'Det finns inte tillräckligt med svar för att visa resultatet.',
		en: 'There are not enough responses to show the result.'
	},
	downloadData: {
		se: 'Ladda ner data',
		en: 'Download data',
	},

	resetPlayerModule: {
		title: {
			se: `Återställ modulgrupp`,
			en: 'Reset module group',
		},
		text: {
			se: `Vill du återställa användarens data för %moduleGroup%?
				<br /><br/>Detta kan inte ångras.`,
			en: `Do you want to reset the user's data for %moduleGroup%?
				<br /><br/>This cannot be reversed.`,
		},
		confirmBtn: {
			se: 'Fortsätt',
			en: 'Continue',
		},
		cancelBtn: {
			se: 'Avbryt',
			en: 'Cancel',
		}
	},

	resetPlayerPopup: {
		text: {
			se: `Vill du återställa användarens framsteg?
				<br /><br />Om detta görs måste de spela om alla moduler.`,
			en: `Do you want to reset this user's progress?
				<br /><br />This will reguire for them to replay all modules.`,
		},
		noBtn: {
			se: 'Nej, håll dem aktiva',
			en: 'No, keep active',
		},
		yesBtn: {
			se: 'Ja, återställ och aktivera',
			en: 'Yes, reset and activate',
		},
		cancelBtn: {
			se: 'Avbryt',
			en: 'Cancel',
		}
	},
};


module.exports = {
	facilitatorUiTexts
};