// NOTE: remember to update the UI-texts file on the drive, if you update this file */

const adminUiTexts = {
	download: {
		se: 'Ladda ner',
		en: 'Download'
	},
	downloadFile: {
		se: 'Ladda ner fil',
		en: 'Download file'
	},
	export: {
		se: 'Exportera',
		en: 'Export'
	},
	modules: {
		se: 'Modulera',
		en: 'Modules'
	},
	exportModuleData: {
		se: 'Exportera moduldata',
		en: 'Export module data'
	},
	facilitatorBtn: {
		se: 'Kontaktperson',
		en: 'Facilitator'
	},
	createNewGame: {
		se: 'Skapa nytt spel',
		en: 'Create new game'
	},
	downloadPlayerProgress: {
		se: 'Ladda ner data för anställd',
		en: 'Download employee data'
	},
	viewFullGameStats: {
		se: 'Visa fullständig statistiik',
		en: 'View full statistics'
	},
	fullStats: {
		se: 'Fullständig statistik för alla spel',
		en: 'Full statistics for all games'
	},
	name: {
		se: 'Namn',
		en: 'Name'
	},
	created: {
		se: 'Skapad',
		en: 'Created'
	},
	createdBy: {
		se: 'Skapad av',
		en: 'Created by'
	},
	scenario: {
		se: 'Scenario',
		en: 'Scenario'
	},
	gameUrl: {
		se: 'URL',
		en: 'URL'
	},
	facilitators: {
		se: 'Kontaktpersoner',
		en: 'Facilitators'
	},
	invited: {
		se: 'Inbjudna',
		en: 'Invited'
	},
	players: {
		se: 'Spelare',
		en: 'Players'
	},
	gameName: {
		se: 'Spelnamn',
		en: 'Game name'
	},
	viewGameInfo: {
		se: 'Visa info',
		en: 'View info'
	},
	viewGameStats: {
		se: 'Visa statistik',
		en: 'View stats'
	},

	surveyPopup: {
		allScenarios: {
			se: 'Alla scenarion',
			en: 'All scenarios'
		}
	},

	playerProgress: {
		employeeData: {
			se: 'Data för anställd',
			en: 'Employee data'
		},
		course: {
			se: 'Kurs',
			en: 'Course',
		},
		courseId: {
			se: 'Kurs-id',
			en: 'Course id',
		},
		employeeId: {
			se: 'Anställningsnummer',
			en: 'Employee number'
		},
		startDate: {
			se: 'Startdatum (datum för slutförande) dd.mm.åååå',
			en: 'Start date (completion date) dd.mm.yyyy'
		},
		endDate: {
			se: 'Slutdatum (utgångs-/förnyelsedatum) dd.mm.åååå',
			en: 'End date (expiration / renewal date) dd.mm.yyyy'
		}
	},

	gamePopup: {
		addFacilitator: {
			se: 'Lägg till kontaktperson',
			en: 'Add facilitator'
		},
		removeFacilitator: {
			se: 'Ta bort kontaktperson',
			en: 'Remove facilitator'
		},
		deleteGame: {
			se: 'Radera spel',
			en: 'Delete game'
		},
		email: {
			se: 'E-post',
			en: 'E-mail'
		},
		lastLogin: {
			se: 'Senaste inloggning',
			en: 'Last login'
		}
	},

	gameStatsPopup: {
		backBtn: {
			se: 'Tillbaka',
			en: 'Back'
		},
		moduleGroup: {
			se: 'Modulgrupp',
			en: 'Module group'
		},
		module: {
			se: 'Modul',
			en: 'Module'
		},
		totalPlayers: {
			se: 'Totalt antal spelare',
			en: 'Total number of players'
		},
		numberOfPlayers: {
			se: 'Antal spelare',
			en: 'Number of players'
		},
		timeSpent: {
			se: 'Spenderad tid (genomsnitt)',
			en: 'Time spent (avg)'
		},
		minutes: {
			se: 'minuter',
			en: 'minutes'
		},
		numberOfErrors: {
			se: 'Antal fel (genomsnitt)',
			en: 'Number of errors (avg)'
		},
		completed: {
			se: 'Slutförd',
			en: 'Completed'
		},
		task: {
			se: 'Uppgift',
			en: 'Task'
		},
		type: {
			se: 'Typ',
			en: 'Type'
		},
		mistakes: {
			se: 'Misstag (genomsnitt)',
			en: 'Mistakes (avg)'
		},
		perfectAttempts: {
			se: 'Perfekt (%)',
			en: 'Perfect (%)'
		},
		attempts: {
			se: 'Försök',
			en: 'Attempts'
		}
	},

	gameStatsDownload: {
		games: {
			se: 'Spel',
			en: 'Games'
		},
		generalStats: {
			se: 'Allmän statistik',
			en: 'General stats'
		},
		downloadStats: {
			se: 'Ladda ner statistik',
			en: 'Download statistics'
		}
	},

	addGamePopup: {
		title: {
			se: 'Skapa nytt spel',
			en: 'Create new game'
		},
		gameNamePlaceholder: {
			se: 'spelnamn',
			en: 'game name'
		},
		gameUrlPlaceholder: {
			se: 'spel-url',
			en: 'game url'
		},
		facilitatorPlaceholder: {
			se: 'kontaktpersonens e-post',
			en: 'facilitator e-mail'
		},
		okBtn: {
			se: 'Skapa spel',
			en: 'Create game'
		},
		emptyField: {
			se: 'Fyll i detta fält',
			en: 'Please fill out this field'
		},
		reservedUrl: {
			se: 'Denna URL är reserverad',
			en: 'This URL is reserved'
		},
		invalidUrl: {
			se: 'Ogiltig URL (giltigt exempel: spel-1)',
			en: 'Invalid URL (valid example: game-1)'
		},
		notUniqueUrl: {
			se: 'Denna URL används redan',
			en: 'This URL is already in use'
		},
		invalidName: {
			se: 'Ogiltigt namn',
			en: 'Invalid name'
		},
		invalidEmail: {
			se: 'Ogiltig e-post',
			en: 'Invalid e-mail'
		}
	},

	addFacilitatorToGamePopup: {
		title: {
			se: 'Lägg till kontaktperson',
			en: 'Add facilitator'
		},
		emailPlaceholder: {
			se: 'e-post',
			en: 'email'
		},
		okBtn: {
			se: 'OK',
			en: 'OK'
		},
		emptyField: {
			se: 'Fyll i e-postfältet.',
			en: 'Please fill out the e-mail field.'
		},
		invalidEmail: {
			se: 'Ogiltigt e-postformat.',
			en: 'Invalid e-mail format.'
		},
		alreadyFacilitator: {
			se: 'Användaren är redan en kontaktperson i spelet.',
			en: 'This user is already a facilitator of this game.'
		}
	},

	removeFacilitatorFromGamePopup: {
		title: {
			se: 'Ta bort kontaktperson från spel',
			en: 'Remove facilitator from game'
		},
		text: {
			se: 'Är du säker på att du vill ta bort kontaktpersonen %email%?<br /><br />Kontaktpersonen kommer inte längre ha åtkomst till detta spel.',
			en: 'Are you sure you want to remove the facilitator %email%?<br /><br />The facilitator will no longer have access to this game.'
		},
		confirmBtn: {
			se: 'Ja, ta bort',
			en: 'Yes, remove'
		},
			
		cancelBtn: {
			se: 'Nej, avbryt',
			en: 'No, cancel'
		}
	},

	deleteGamePopup: {
		title: {
			se: 'Radera spel',
			en: 'Delete game'
		},
		text1: {
			se: 'Är du säker på att du vill radera spelet %gameName%?<br /><br />Alla spel- och spelardata kommer att raderas.',
			en: 'Are you sure you want to delete the game %gameName%?<br /><br />All game and player data will be deleted.',
		},
		text2: {
			se: `Bekräfta att du vill radera spelet %gameName%.`,
			en: `Please confirm that you want to delete %gameName%.`
		},
		confirmBtn: {
			se: 'Ja, radera',
			en: 'Yes, delete'
		},
		cancelBtn: {
			se: 'Nej, avbryt',
			en: 'No, cancel'
		}
	},
	
	textToSpeechPopup: {
		title: {
			se: 'Text till tal',
			en: 'Text to speech',
		},
		configuration: {
			se: 'Konfiguration',
			en: 'Configuration'
		},
		language: {
			se: 'Språk',
			en: 'Language'
		},
		voice: {
			se: 'Röst',
			en: 'Voice'
		},
		modules: {
			se: 'Moduler',
			en: 'Modules'
		},
		uploadBtn: {
			se: 'Ladda upp texter',
			en: 'Upload texts'
		}
	}
};

module.exports = {
	adminUiTexts
};